import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { startTransition, StrictMode } from 'react'
import { hydrateRoot } from 'react-dom/client'
import { I18nextProvider, initReactI18next } from 'react-i18next'
import { HydratedRouter } from 'react-router/dom'
import { i18nConfig } from '~/utils/i18n.ts'

if (ENV.MODE === 'production' && ENV.SENTRY_DSN) {
  void import('./utils/monitoring.client.tsx').then(({ init }) => init())
}

async function hydrate() {
  await i18next
    .use(initReactI18next)
    // @ts-ignore
    .use(LanguageDetector)
    .use(Backend)
    .init({
      ...i18nConfig,
      backend: {
        loadPath: `/locales/{{lng}}/{{ns}}.json?bn=${window.ENV.GH_BUILD_NUMBER}`,
      },
      detection: {
        order: ['htmlTag'],
        caches: [],
      },
    })

  startTransition(() => {
    hydrateRoot(
      document,
      // @ts-ignore
      <I18nextProvider i18n={i18next}>
        <StrictMode>
          <HydratedRouter />
        </StrictMode>
      </I18nextProvider>,
    )
  })
}

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate)
} else {
  window.setTimeout(hydrate, 1)
}
